<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <!--BUDGET IMPORT FROM EXCEL POPUP -->

    <vs-popup
      :button-close-hidden="false"
      class="holamundo p-2 assigneePopup"
      title="Add Value Head "
      :active.sync="popupActivo2"
    >
      <div class="text-center position-relative">
        <form>
          <div class="row">
            <div class="col-12 mb-4">
              Head Name:<span style="font-weight: bold">{{
                selectedHead
              }}</span>
              Date :<span style="font-weight: bold">{{ selectedDate }}</span>
            </div>
            <div class="col-4"></div>
            <div class="col-4">
              <!-- Start ************* -->
              <div class="mb-4">
                <vs-input
                  class=""
                  v-model="addheader"
                  :placeholder="placeholder"
                />

                <p
                  class="error-msg"
                  style="text-align: left"
                  v-if="headmissing"
                >
                  {{ headmissing }}
                </p>
              </div>
              <!-- ************* End -->
            </div>
          </div>

          <!-- SUBMIT BUTTON -->
          <div class="vx-row">
            <div class="vx-col mx-auto">
              <!-- :disabled="submitStatus"
              @click.prevent="submitForm"-->
              <vs-button
                type="filled"
                class="mr-3"
                @click.prevent="AddValueHead"
                >Submit</vs-button
              >
              <!-- <vs-button color="warning" type="border" class @click.prevent="clearForm">Reset</vs-button> -->
            </div>
          </div>
        </form>
      </div>
    </vs-popup>

    <vs-popup
      :button-close-hidden="false"
      class="holamundo p-2 assigneePopup"
      title="Add New Budget Header"
      :active.sync="popupActivo1"
    >
      <div class="text-center position-relative">
        <form>
          <div class="row">
            <div class="col-4"></div>
            <div class="col-4">
              <!-- Start ************* -->
              <div class="mb-4">
                <vs-input
                  class=""
                  v-model="addheader"
                  placeholder="Add New Head"
                />

                <p
                  class="error-msg"
                  style="text-align: left"
                  v-if="headmissing"
                >
                  {{ headmissing }}
                </p>
              </div>
              <!-- ************* End -->
            </div>
          </div>

          <!-- SUBMIT BUTTON -->
          <div class="vx-row">
            <div class="vx-col mx-auto">
              <!-- :disabled="submitStatus"
              @click.prevent="submitForm"-->
              <vs-button type="filled" class="mr-3" @click.prevent="AddHead"
                >Submit</vs-button
              >
              <!-- <vs-button color="warning" type="border" class @click.prevent="clearForm">Reset</vs-button> -->
            </div>
          </div>
        </form>
      </div>
    </vs-popup>

    <vs-popup
      :button-close-hidden="false"
      class="holamundo p-2 assigneePopup"
      title="Import Budget from Excel"
      :active.sync="popupActivo"
    >
      <div class="text-center position-relative">
        <form>
          <div class="row">
            <div class="col-8">
              <!-- Start ************* -->
              <div>
                <multiselect
                  v-validate="'required'"
                  v-model="projects_value"
                  track-by="project_id"
                  open-direction="bottom"
                  label="project_name"
                  @input="({ project_id }) => (this.project_id = project_id)"
                  :options="projects"
                  name="Project Name"
                  placeholder="Select Project"
                  :searchable="true"
                  :allow-empty="false"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.project_name }}
                  </template>
                </multiselect>
                <p
                  class="error-msg"
                  style="text-align: left"
                  v-if="pprojectmissing"
                >
                  {{ pprojectmissing }}
                </p>
              </div>
              <!-- ************* End -->
            </div>

            <div class="col-4">
              <vx-tooltip text="Import Excel" position="left">
                <label for="import-file">
                  <!-- @change="uploadExcel($event)" -->
                  <input
                    :disabled="this.project_id == null ? true : false"
                    ref="uploadExcelFile"
                    type="file"
                    accept=".xls,.xlsx"
                    @change="uploadExcel($event)"
                    name="import-file"
                    id="import-file"
                    class="d-none"
                  />
                  <!-- class="d-none" -->
                  <div
                    class="vs-component vs-button px-4 vs-button-primary vs-button-filled"
                    :style="
                      this.project_id == null
                        ? 'opacity: .5; cursor: default;pointer-events: none;'
                        : ''
                    "
                  >
                    <span class="d-inline-flex pr-5 text-white">Import</span>
                    <img
                      class="d-inline-flex"
                      src="@/assets/images/icons/import-excel.svg"
                      height="20"
                      width="20"
                    />
                  </div>
                </label>
              </vx-tooltip>
            </div>
          </div>

          <!-- SUBMIT BUTTON -->
          <div class="vx-row">
            <div class="vx-col mx-auto">
              <!-- :disabled="submitStatus"
              @click.prevent="submitForm"-->
              <vs-button type="filled" class="mr-3" @click.prevent="submitExcel"
                >Submit</vs-button
              >
              <!-- <vs-button color="warning" type="border" class @click.prevent="clearForm">Reset</vs-button> -->
            </div>
          </div>
        </form>
      </div>
    </vs-popup>

    <div class="vx-col w-full">
      <router-link to="/apps/projects">
        <feather-icon icon="ArrowLeftCircleIcon" svgClasses="h-8 w-8" />
      </router-link>
    </div>
    <div class="row">
      <div class="col-12">
        <vx-card class="form-title" title="Get Daily Cost Report">
          <div class="col-6 mx-auto mb-4">
            <div class="mb-4">
              <multiselect
                v-model="projectSelected"
                track-by="project_id"
                label="project_name"
                :options="projects"
                name="Project Name"
                placeholder="Select Project"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.project_name }}
                </template>
              </multiselect>
              <p class="error-msg text-sm mb-2" v-if="projectmissing != ''">
                {{ projectmissing }}
              </p>
            </div>
            <div cass="row">
              <div class="col-3">
                <h6>Date Range</h6>
              </div>
              <!-- :disabledDates="startDisabledDates" -->

              <div class="col-6" style="float: left">
                <datepicker
                  placeholder="From Date"
                  @selected="onSelectDate($event)"
                  format="dd-MM-yyyy"
                  v-model="dateFrom"
                ></datepicker>
              </div>
              <!-- -->
              <div class="col-6" style="float: left">
                <datepicker
                  placeholder="To Date"
                  :disabledDates="endDisabledDates"
                  v-model="dateTo"
                  format="dd-MM-yyyy"
                  :disabled="toDateDisable"
                ></datepicker>
              </div>
              <div class="col-12 mx-auto" style="clear: both">
                <p class="error-msg text-sm" v-if="datemmissing != ''">
                  {{ datemmissing }}
                </p>
              </div>
            </div>
          </div>
          <div class="text-center" style="clear: both">
            <vs-button
              color="primary"
              class="text-center"
              type="filled"
              @click="searchAndPopulate"
              >Search</vs-button
            >
          </div>

          <div class="vx-col">
            <div class="excel-btn-1" v-if="OrganizationID == 4">
              <vx-tooltip text="Template Excel" position="top">
                <vs-button
                  @click.native="
                    onClickSampleDownload(
                      BASEURL +
                        `/v2/dcrhead/excel/sample?organization_id=` +
                        organization_id
                    )
                  "
                  type="border"
                  class="py-2"
                >
                  <img
                    class="d-inline-flex"
                    src="@/assets/images/icons/excel.svg"
                    height="20"
                    width="20"
                  />
                </vs-button>
                <!-- </a> -->
              </vx-tooltip>
            </div>

            <div class="excel-btn-1" v-else>
              <vx-tooltip text="Template Excel" position="top">
                <vs-button
                  @click.native="
                    onClickSampleDownload(
                      BASEURL + `/v2/budget/excel/yrfentsample`
                    )
                  "
                  type="border"
                  class="py-2"
                >
                  <img
                    class="d-inline-flex"
                    src="@/assets/images/icons/excel.svg"
                    height="20"
                    width="20"
                  />
                </vs-button>
                <!-- </a> -->
              </vx-tooltip>
            </div>

            <div class="excel-btn-2">
              <vs-button
                @click="popupActivo = true"
                class="px-2 py-3"
                color="primary"
                type="border"
              >
                <img
                  class="d-inline-flex mr-2"
                  src="@/assets/images/icons/excel.svg"
                  height="20"
                  width="20"
                  alt
                />Import from Excel
              </vs-button>
            </div>
          </div>
        </vx-card>
      </div>
    </div>

    <!-- DEPARTMENTS LIST -->
    <div id="ag-grid-demo " class="mt-5">
      <vx-card v-if="alldates.length > 0">
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4 class>DCR List</h4>
          <div class="flex flex-wrap justify-end items-center">
            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div
              class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
            >
              <!-- <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              /> -->
            </div>
            <vs-button
              color="primary"
              class="mr-4 hover-btn"
              type="filled"
              @click="popupActivo1 = true"
              >Add New</vs-button
            >

            <!-- <vs-button color="primary" class="mr-4 hover-btn" type="filled"
              >Delete Budget</vs-button
            > -->

            <vx-tooltip text="Export Excel">
              <!-- Before Select Project -->
              <template v-if="!this.projectSelected.project_id">
                <vs-button class="px-4" @click.prevent="selectProject">
                  <span class="d-inline-flex pr-5 text-white">Export</span>
                  <img
                    src="@/assets/images/icons/export-excel.svg"
                    class="d-inline-flex"
                    height="20"
                    width="20"
                  />
                </vs-button>
              </template>

              <!-- After Select Project -->
              <template v-else>
                <a
                  :href="
                    BASEURL +
                    `/v2/dcrhead/download/${this.projectSelected.project_id}?token=${temp_token}&organization_id=${organization_id}`
                  "
                  target="_blank"
                >
                  <vs-button class="px-4">
                    <span class="d-inline-flex pr-5 text-white">Export</span>
                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </template>
            </vx-tooltip>
          </div>
        </div>

        <div class="col-12 pl-0 chart-table chart-table-data chart-table-right">
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th
                    class="text-center sb-th sb-fixed1"
                    rowspan="3"
                    scope="col"
                  >
                    Budget Head
                  </th>
                  <th
                    class="text-center sb-th sb-fixed"
                    rowspan="3"
                    scope="col "
                  >
                    Total(AMT)
                  </th>
                  <th
                    class="text-center sb-th"
                    :colspan="alldates.length * 2"
                    scope="col"
                  >
                    Date
                  </th>
                </tr>
                <tr>
                  <th
                    class="text-center sb-th"
                    colspan="2"
                    scope="col"
                    :key="index"
                    v-for="(col, index) in alldates"
                  >
                    {{ alldates[index] }}
                  </th>
                </tr>
                <tr>
                  <!-- <div :key="'s' + index" v-for="(col, index) in alldates"> -->
                  <th
                    class="text-center sb-th"
                    scope="col"
                    v-for="(item, index) in alldates.length * 2"
                    :key="'s' + index"
                  >
                    <span v-if="(index + 1) % 2 == 0">AMT</span>
                    <span v-else>QTY</span>
                  </th>

                  <!-- </div> -->
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item, index) in alldata" :key="'b' + index">
                  <!-- USER FOR LOOP IN 'TR' TAG FOR TABLE ROWS ::: -->
                  <th class="sb-fixed1">{{ alldata[index].dcrheader_name }}</th>
                  <th class="sb-fixed">{{ alldata[index].total }}</th>
                  <th
                    class="text-center sb-th"
                    scope="col"
                    v-for="(item1, index1) in alldates.length * 2"
                    :key="'s_' + index + '_' + index1"
                  >
                    <span
                      @click="
                        editAddQty(
                          alldata[index].dcrheader_id,
                          alldata[index].dcrheader_name,
                          alldates[Math.floor(index1 * 0.5)],
                          getDateAmt(
                            alldates[Math.floor(index1 * 0.5)],
                            alldata[index].childs
                          ),
                          (index1 + 1) % 2 == 0
                        )
                      "
                      v-if="(index1 + 1) % 2 == 0"
                      >{{
                        getDateAmt(
                          alldates[Math.floor(index1 * 0.5)],
                          alldata[index].childs
                        )
                      }}</span
                    >
                    <span
                      v-else
                      @click="
                        editAddAmt(
                          alldata[index].dcrheader_id,
                          alldata[index].dcrheader_name,
                          alldates[Math.floor(index1 * 0.5)],
                          getDateQty(
                            alldates[Math.floor(index1 * 0.5)],
                            alldata[index].childs
                          )
                        )
                      "
                      >{{
                        getDateQty(
                          alldates[Math.floor(index1 * 0.5)],
                          alldata[index].childs
                        )
                      }}</span
                    >
                  </th>
                </tr>
                <tr>
                  <th class="sb-fixed1 sb-col">Total</th>
                  <th class="sb-fixed sb-col">{{ total_amount }}</th>
                  <th
                    class="text-center sb-th sb-col"
                    scope="col"
                    v-for="(item1, index1) in alldates.length * 2"
                    :key="'ss_' + index + '_' + index1"
                  ></th>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- ******************************** -->
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { BASEURL } from "@/config/index.js";

import Datepicker from "vuejs-datepicker";
import moment from "moment";
import projectService from "@/services/projectService.js";
export default {
  components: {
    Datepicker,
  },
  mounted() {
    this.organization_id = localStorage.getItem("OrganizationID");

    this.getProjectsList();
  },
  data() {
    return {
      total_amount: 0,
      placeholder: "",
      addheader: "",
      items: [],
      // submitStatus: false,
      projectSelected: null,
      projects_value: [],
      BASEURL,
      pprojectmissing: "",
      project_id: 0,
      projectmissing: "",
      projectId: 0,
      searchQuery: "",
      OrganizationID: 4,
      startDisabledDates: {
        from: new Date(),
      },
      endDisabledDates: {
        from: null,
        to: new Date(),
      },
      dateFrom: "",
      dateTo: "",
      projects: [],
      // projectSelected: null,
      popupActivo: false,
      popupActivo1: false,
      popupActivo2: false,
      toDateDisable: true,
      datemmissing: "",
      organization_id: 0,
      excelUpload: null,
      alldates: [],
      alldata: null,
      headmissing: "",
      selectedHeadID: "",
      selectedHead: "",
      selectedDate: "",
    };
  },
  methods: {
    AddValueHead() {
      this.headmissing = "";
      if (this.addheader == "") {
        this.headmissing = "Please Enter value";
      }
      var type = "Amt";
      if (this.placeholder == "Add/Edit Quantity") {
        type = "qty";
      }
      if (this.headmissing == "") {
        projectService
          .updateDCRHeadValue(this.projectSelected.project_id, {
            id: this.selectedHeadID,
            date: this.selectedDate,
            value: this.addheader,
            type: type,
          })
          .then((response) => {
            const { data } = response;
            if (data.Status) {
              this.popupActivo2 = false;
              this.$vs.notify({
                title: "Add/Edit Header Value",
                text: data.Message,
                time: 5000,
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });

              projectService
                .getDCRHead(this.projectSelected.project_id)
                .then((response) => {
                  const { data } = response;
                  if (data.Status) {
                    this.alldata = data.data;
                    this.alldates = data.dates;

                    this.total_amount = 0;
                    for (let i = 0; i < this.alldata.length; i++) {
                      this.total_amount += this.alldata[i].total;
                    }

                    if (this.dateFrom != "" && this.dateTo != "") {
                      this.alldates = [];
                      // console.log(this.dateFrom, this.dateTo);
                      let from = moment(this.dateFrom);
                      let to = moment(this.dateTo);
                      // 2021-11-25
                      while (
                        from.format("DD-MM-YYYY") !== to.format("DD-MM-YYYY")
                      ) {
                        let date = from.add(1, "days").format("YYYY-MM-DD");

                        // console.log(this.alldates.indexOf(date));
                        if (this.alldates.indexOf(date) === -1) {
                          this.alldates.push(date);
                        }
                      }
                    }
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              this.$vs.notify({
                title: "Add/Edit Header Value",
                text: data.Message,
                time: 5000,
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    editAddAmt(id, name, date, value = 0) {
      this.popupActivo2 = true;
      this.selectedHeadID = id;
      this.selectedHead = name;
      this.selectedDate = date;
      this.placeholder = "Add/Edit Quantity";
      this.addheader = value;
    },
    editAddQty(id, name, date, value = 0) {
      console.log("editAddQty");
      this.popupActivo2 = true;
      this.selectedHeadID = id;
      this.selectedHead = name;
      this.selectedDate = date;
      this.placeholder = "Add/Edit Amount";
      this.addheader = value;
    },
    AddHead() {
      this.headmissing = "";
      if (this.addheader == "") {
        this.headmissing = "Please Enter a new Budget Head";
      }
      if (this.headmissing == "") {
        projectService
          .addDCRHead(this.projectSelected.project_id, { name: this.addheader })
          .then((response) => {
            const { data } = response;
            if (data.Status) {
              this.popupActivo1 = false;
              this.searchAndPopulate();
              // this.alldata = data.data;
              // this.alldates = data.dates;
            } else {
              this.$vs.notify({
                title: "Add New Header",
                text: data.Message,
                time: 5000,
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getDateQty(date, childs) {
      for (let i = 0; i < childs.length; i++) {
        if (childs[i].date == date) {
          return childs[i].qty;
        }
      }
      return "0";
    },
    getDateAmt(date, childs) {
      for (let i = 0; i < childs.length; i++) {
        if (childs[i].date == date) {
          return childs[i].Amt;
        }
      }
      return "0";
    },
    uploadExcel: function (event) {
      this.excelUpload = event.target.files[0];
      this.$vs.notify({
        title: "Your file is imported",
        text: "Kindly click on the SUBMIT button to upload data succesfully",
        time: 5000,
        iconPack: "feather",
        icon: "check_box",
        color: "warning",
      });
    },
    onClickSampleDownload: function (URL) {
      console.log(URL);
      window.open(URL, "_blank");
    },
    submitExcel: function () {
      console.log(this.projects_value, "");
      if (this.projects_value != 0) {
        this.pprojectmissing = "";
      } else {
        this.pprojectmissing = "Select project";
        return;
      }

      if (this.excelUpload) {
        this.pprojectmissing = "";
      } else {
        this.pprojectmissing = "Select Excel to Upload";
        return;
      }

      let that = this;
      that.$vs.loading();
      let formData = new FormData();
      formData.append("file", this.excelUpload);
      formData.append("project_id", this.projects_value.project_id);
      axios
        .post(
          that.BASEURL +
            `/v2/dcrhead/importData/${this.projects_value.project_id}?organization_id=${that.organization_id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          const { data } = response;
          const input = this.$refs.uploadExcelFile;
          input.type = "text";
          input.type = "file";
          if (
            data &&
            data["error"] &&
            data.error["message"] &&
            data.error.message.length > 0
          ) {
            that.$vs.notify({
              title: "Error!",
              // title: "Duplicate entries have not been imported.",
              text: data.error.message || "Error",
              iconPack: "feather",
              time: 35000,
              icon: "check_box",
              color: "warning",
            });
          } else {
            that.$vs.notify({
              title: "Updated!",
              text: data.data.message || "Updated",
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            this.popupActivo = false;
          }

          that.$vs.loading.close();
          // that.$vs.notify({
          //   title: "Updated!",
          //   text: data.message,
          //   iconPack: "feather",
          //   icon: "check_box",
          //   color: "success"
          // });
          eventBus.$emit("refreshTabledepa", data);
        })
        .catch((error) => {
          that.$vs.loading.close();
          this.popupActivo = false;
          const input = this.$refs.uploadExcelFile;
          input.type = "text";
          input.type = "file";
          that.$vs.notify({
            title: "Error!",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    selectProject: function () {
      // console.log("projectSelected", projectSelected);
      // this.projectId = this.projectSelected.project_id;
      this.$vs.notify({
        time: 5000,
        title: "Select Project",
        iconPack: "feather",
        icon: "check_box",
        color: "warning",
      });
    },
    searchAndPopulate() {
      console.log(
        "searchAndPopulate",
        this.projectSelected,
        this.dateFrom,
        this.dateTo
      );
      if (!this.projectSelected) {
        this.projectmissing = "Select a project ";
      } else {
        this.projectmissing = "";
      }
      if (this.projectmissing == "" && this.datemmissing == "") {
        projectService
          .getDCRHead(this.projectSelected.project_id)
          .then((response) => {
            const { data } = response;
            if (data.Status) {
              this.alldata = data.data;
              this.alldates = data.dates;
              // console.log(this.dateFrom, this.dateTo);
              this.total_amount = 0;
              for (let i = 0; i < this.alldata.length; i++) {
                this.total_amount += this.alldata[i].total;
              }
              if (this.dateFrom != "" && this.dateTo != "") {
                this.alldates = [];
                // console.log(this.dateFrom, this.dateTo);
                let from = moment(this.dateFrom);
                let to = moment(this.dateTo);
                // 2021-11-25
                while (from.format("DD-MM-YYYY") !== to.format("DD-MM-YYYY")) {
                  let date = from.add(1, "days").format("YYYY-MM-DD");

                  // console.log(this.alldates.indexOf(date));
                  if (this.alldates.indexOf(date) === -1) {
                    this.alldates.push(date);
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }

      // this.alldates

      //:::
    },
    updateSearchQuery(val) {},
    onSelectDate: function ($event) {
      // console.log("In");
      this.datemmissing = "";
      this.endDisabledDates["to"] = new Date(moment(new Date($event)).format());
      this.dateTo = new Date($event);
      this.toDateDisable = false;
      // console.log(this.endDisabledDates);
    },
    getProjectsList: function () {
      this.projects = [];
      projectService
        .getAllProjects()
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.projects = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  // data() {
  //   return {
  //     OrganizationID: window.localStorage.getItem("OrganizationID"),
  //     permissionPageAccessArry: window.localStorage.getItem(
  //       "permissionPageAccess"
  //     )
  //       ? localStorage.getItem("permissionPageAccess").split(",")
  //       : "",
  //     PermissionVariables: PermissionVariables,
  //     loginUserRole: window.localStorage.getItem("UserRole"),
  //     excelurl: BASEURL,
  //     categories: [],
  //     subCategories: [],
  //     categoryTemp: [],
  //     SubcategoryTemp: [],
  //     totalPages: 0,
  //     overlayLoadingTemplate: null,
  //     submitStatus: true,
  //     searchPayload: {},
  //     archive: this.listType,
  //     departments: [],
  //     User: "Select User",
  //     switchValue: true,
  //     gridOptions: {},
  //     searchBudgetList: [],
  //     budgetCodes: [],
  //     maxPageNumbers: 7,
  //     popupActive: false,
  //     popupActiveDeleteBudget: false,
  //     gridApi: null,
  //     defaultColDef: {
  //       sortable: true,
  //       editable: false,
  //       resizable: true,
  //       suppressMenu: true,
  //     },
  //     project_id: null,
  //     form: {
  //       selectedCategory: "",
  //       selectedSubCategory: "",
  //       budgetCode_value: "",
  //       budgetAmount: "",
  //       budget_active: "1",
  //     },
  //     budgetCodesList: ["ATL", "BTL", "MUSIC", "OTHER", "POST", "VFX"],
  //     fromDate: null,
  //     toDate: null,
  //     configFromdateTimePicker: {
  //       minDate: new Date(),
  //       maxDate: null,
  //     },
  //     configTodateTimePicker: {
  //       minDate: null,
  //     },
  //     contacts: contacts,
  //     columnDefs: [],
  //     organization_id: "",
  //   };
  // },
  // beforeMount() {
  //   this.organization_id = localStorage.getItem("OrganizationID");
  //   if (this.organization_id == 4) {
  //     this.columnDefs = [
  //       {
  //         headerName: "Budget Code",
  //         field: "budget_code_num",
  //         filter: false,
  //         width: 150,
  //         suppressSizeToFit: true,
  //         cellRendererFramework: viewExpenseDetails,
  //       },
  //       {
  //         headerName: "Category",
  //         field: "category_name",
  //         filter: false,
  //         width: 250,
  //         headerTooltip: "Category",
  //         tooltipComponent: "customTooltip",
  //         cellRendererFramework: viewExpenseDetails,
  //       },
  //       {
  //         headerName: "Subcategory",
  //         field: "sub_category_name",
  //         filter: false,
  //         width: 250,
  //         cellRendererFramework: viewExpenseDetails,
  //       },
  //       {
  //         headerName: "Budget Amount",
  //         field: "formattedBudgetAmount",
  //         filter: false,
  //         width: 250,
  //         cellRendererFramework: viewExpenseDetails,
  //       },
  //       {
  //         headerName: "Contractual Unpaid",
  //         field: "contractAmount",
  //         filter: false,
  //         width: 250,
  //       },
  //       {
  //         headerName: "Daily Cost Report",
  //         field: "projectAmount",
  //         filter: false,
  //         width: 250,
  //       },
  //       {
  //         headerName: "Actual Expense",
  //         field: "actual_amount",
  //         filter: false,
  //         width: 250,
  //         cellRendererFramework: viewExpenseDetails,
  //       },
  //       // {
  //       //   headerName: "Paid Amount",
  //       //   field: "actual_amount",
  //       //   filter: false,
  //       //   width: 250,
  //       //   // cellRendererFramework: viewExpenseDetails,
  //       // },
  //       {
  //         headerName: "Credit Amount",
  //         field: "credit_amount",
  //         filter: false,
  //         width: 120,
  //         cellRenderer: function (params) {
  //           return `<div class="" style="color:red">${params.data.credit_amount} <div>`;
  //         },
  //         // cellRendererFramework: viewAllExpenseDetails
  //       },
  //       {
  //         headerName: "Balance Amount",
  //         field: "balance_amount",
  //         filter: false,
  //         width: 250,
  //         cellRendererFramework: viewExpenseDetails,
  //       },
  //       {
  //         headerName: "Allow to add expense",
  //         field: "",
  //         filter: false,
  //         width: 200,
  //         sortable: false,
  //         hide:
  //           (this.archive == "archive" ||
  //             !this.permissionPageAccessArry.includes(
  //               PermissionVariables.BudgetEdit
  //             )) &&
  //           this.loginUserRole != "Admin"
  //             ? true
  //             : false,
  //         cellStyle: { "text-align": "center" },
  //         cellRendererFramework: isAllowedBudget,
  //       },
  //       {
  //         headerName: "Status",
  //         field: "budget_active",
  //         filter: false,
  //         width: 120,
  //         sortable: false,
  //         hide:
  //           (this.archive == "archive" ||
  //             !this.permissionPageAccessArry.includes(
  //               PermissionVariables.BudgetEdit
  //             )) &&
  //           this.loginUserRole != "Admin"
  //             ? true
  //             : false,
  //         cellStyle: { "text-align": "center" },
  //         cellRendererFramework: budgetStatus,
  //       },
  //       {
  //         headerName: "Edit",
  //         field: "budget_id",
  //         filter: false,
  //         width: 120,
  //         hide:
  //           (this.archive == "archive" ||
  //             !this.permissionPageAccessArry.includes(
  //               PermissionVariables.BudgetEdit
  //             )) &&
  //           this.loginUserRole != "Admin"
  //             ? true
  //             : false,
  //         sortable: false,
  //         cellStyle: { "text-align": "center" },
  //         cellRendererFramework: EditBudget,
  //       },
  //       {
  //         headerName: "View",
  //         field: "budget_id",
  //         filter: false,
  //         width: 120,
  //         sortable: false,
  //         cellStyle: { "text-align": "center" },
  //         cellRendererFramework: viewBudgetDetails,
  //       },
  //     ];
  //   } else {
  //     this.columnDefs = [
  //       {
  //         headerName: "Budget Code",
  //         field: "budget_code_num",
  //         filter: false,
  //         width: 150,
  //         suppressSizeToFit: true,
  //         cellRendererFramework: viewExpenseDetails,
  //       },
  //       {
  //         headerName: "Category",
  //         field: "category_name",
  //         filter: false,
  //         width: 250,
  //         headerTooltip: "Category",
  //         tooltipComponent: "customTooltip",
  //         cellRendererFramework: viewExpenseDetails,
  //       },
  //       {
  //         headerName: "Subcategory",
  //         field: "sub_category_name",
  //         filter: false,
  //         width: 250,
  //         cellRendererFramework: viewExpenseDetails,
  //       },
  //       {
  //         headerName: "Nos",
  //         field: "nos",
  //         filter: false,
  //         width: 250,
  //         cellRendererFramework: viewExpenseDetails,
  //       },
  //       {
  //         headerName: "Period",
  //         field: "period",
  //         filter: false,
  //         width: 250,
  //         cellRendererFramework: viewExpenseDetails,
  //       },
  //       {
  //         headerName: "Rate",
  //         field: "rate",
  //         filter: false,
  //         width: 250,
  //         cellRendererFramework: viewExpenseDetails,
  //       },
  //       {
  //         headerName: "Budget Amount",
  //         field: "formattedBudgetAmount",
  //         filter: false,
  //         width: 250,
  //         cellRendererFramework: viewExpenseDetails,
  //       },
  //       // {
  //       //   headerName: "Contractual Unpaid",
  //       //   field: "contractAmount",
  //       //   filter: false,
  //       //   width: 250,
  //       // },
  //       {
  //         headerName: "Actual Expense",
  //         field: "actual_amount",
  //         filter: false,
  //         width: 250,
  //         cellRendererFramework: viewExpenseDetails,
  //       },
  //       {
  //         headerName: "Paid Amount",
  //         field: "paidAmount",
  //         filter: false,
  //         width: 250,
  //         // cellRendererFramework: viewExpenseDetails,
  //       },
  //       // {
  //       //   headerName: "Credit Amount",
  //       //   field: "credit_amount",
  //       //   filter: false,
  //       //   width: 120,
  //       //   cellRenderer: function (params) {
  //       //     return `<div class="" style="color:red">${params.data.credit_amount} <div>`;
  //       //   },
  //       //   // cellRendererFramework: viewAllExpenseDetails
  //       // },
  //       {
  //         headerName: "Balance Amount",
  //         field: "balance_amount",
  //         filter: false,
  //         width: 250,
  //         cellRendererFramework: viewExpenseDetails,
  //       },
  //       {
  //         headerName: "Allow to add expense",
  //         field: "",
  //         filter: false,
  //         width: 200,
  //         sortable: false,
  //         hide:
  //           (this.archive == "archive" ||
  //             !this.permissionPageAccessArry.includes(
  //               PermissionVariables.BudgetEdit
  //             )) &&
  //           this.loginUserRole != "Admin"
  //             ? true
  //             : false,
  //         cellStyle: { "text-align": "center" },
  //         cellRendererFramework: isAllowedBudget,
  //       },
  //       {
  //         headerName: "Status",
  //         field: "budget_active",
  //         filter: false,
  //         width: 120,
  //         sortable: false,
  //         hide:
  //           (this.archive == "archive" ||
  //             !this.permissionPageAccessArry.includes(
  //               PermissionVariables.BudgetEdit
  //             )) &&
  //           this.loginUserRole != "Admin"
  //             ? true
  //             : false,
  //         cellStyle: { "text-align": "center" },
  //         cellRendererFramework: budgetStatus,
  //       },
  //       {
  //         headerName: "Edit",
  //         field: "budget_id",
  //         filter: false,
  //         width: 120,
  //         hide:
  //           (this.archive == "archive" ||
  //             !this.permissionPageAccessArry.includes(
  //               PermissionVariables.BudgetEdit
  //             )) &&
  //           this.loginUserRole != "Admin"
  //             ? true
  //             : false,
  //         sortable: false,
  //         cellStyle: { "text-align": "center" },
  //         cellRendererFramework: EditBudget,
  //       },
  //       {
  //         headerName: "View",
  //         field: "budget_id",
  //         filter: false,
  //         width: 120,
  //         sortable: false,
  //         cellStyle: { "text-align": "center" },
  //         cellRendererFramework: viewBudgetDetails,
  //       },
  //     ];
  //   }
  //   // this.frameworkComponents = { customTooltip: CustomTooltip };
  //   // this.setColums();
  //   this.gridOptions = {
  //     getRowStyle(params) {
  //       if (
  //         parseInt(params.data.budget_amount) <
  //         parseInt(params.data.actual_amount.replace(/\,/g, ""))
  //       ) {
  //         return { background: "red" };
  //       }
  //       // return null;
  //     },
  //   };
  // },
  // watch: {
  //   "$store.state.windowWidth"(val) {
  //     if (val <= 576) {
  //       this.maxPageNumbers = 4;
  //       this.gridOptions.columnApi.setColumnPinned("email", null);
  //     } else this.gridOptions.columnApi.setColumnPinned("email", "left");
  //   },
  //   form: {
  //     handler: function (newVal, oldVal) {
  //       let len = Object.keys(this.form).length;
  //       if (
  //         len ===
  //         Object.keys(newVal).filter(
  //           (x, index) =>
  //             newVal[x] &&
  //             newVal[x] !== undefined &&
  //             String(newVal[x]).trim().length > 0
  //         ).length
  //       ) {
  //         this.submitStatus = false;
  //       } else {
  //         this.submitStatus = true;
  //       }
  //     },
  //     deep: true,
  //   },
  // },
  // computed: {
  //   is_data() {
  //     return Object.keys(this.budgetList).length > 0;
  //   },
  //   budgetList() {
  //     if (this.projectId) {
  //       if (this.searchQuery != null && this.searchQuery != "") {
  //         return this.searchBudgetList;
  //       } else {
  //         return this.$store.getters.getbudgetlist;
  //       }
  //     } else {
  //       return [];
  //     }
  //   },
  //   isDelete() {
  //     return this.$store.state.is_delete;
  //   },
  //   searchQuery: {
  //     get() {
  //       return this.$store.state.selectedCode;
  //     },
  //     set(value) {
  //       this.$store.dispatch("selectedCode", value);
  //     },
  //   },
  //   budgetPagination() {
  //     return this.$store.state.budgetObject.pagination.last_page;
  //   },
  //   projectId() {
  //     return this.$store.state.budgetObject.projectId;
  //   },
  //   paginationPageSize() {
  //     if (this.gridApi) return this.gridApi.paginationGetPageSize();
  //     else return 100;
  //   },
  //   currentPage: {
  //     get() {
  //       if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
  //       else return 1;
  //     },
  //     set(val) {
  //       if (val) {
  //         // this.getBudgetList(val);
  //         this.$store.dispatch("SetCurrentPageBudget", {
  //           page: val,
  //         });
  //         return val;
  //       } else return 1;
  //     },
  //   },
  // },
  // methods: {
  //   // ...mapActions(["clearBudgetList"]),
  //   onRowSelected(event) {
  //     window.alert(
  //       "row " + event.node.data.athlete + " selected = " + event.node.selected
  //     );
  // //   },
  //   selectProject: function () {
  //   projectSelected
  //     this.$vs.notify({
  //       time: 5000,
  //       title: "Select Project",
  //       iconPack: "feather",
  //       icon: "check_box",
  //       color: "warning",
  //     });
  //   },
  //   onRowDataChanged(e) {
  //     console.log("e :", e);
  //   },
  //   onChangeStatus: function () {
  //     console.log("object");
  //   },
  //   updateSearchQuery(val) {
  //     this.searchPayload = {
  //       project_id: this.projectId,
  //     };
  //     // this.gridApi.setQuickFilter(val);
  //     SearchService.getAllSerchResult(val, "budget/project", this.searchPayload)
  //       .then((response) => {
  //         const { data } = response;
  //         if (data.Status) {
  //           this.searchBudgetList = data.data;
  //         } else {
  //           this.searchBudgetList = [];
  //           // this.$vs.notify({
  //           //   time: 5000,
  //           //   title: "Search data not found",
  //           //   text: data.Message,
  //           //   iconPack: "feather",
  //           //   icon: "check_box",
  //           //   color: "warning",
  //           // });
  //         }
  //       })
  //       .catch((error) => {
  //         this.$vs.notify({
  //           time: 5000,
  //           title: "Data Not Found",
  //           text: error.Message,
  //           iconPack: "feather",
  //           icon: "check_box",
  //           color: "warning",
  //         });
  //       });
  //   },
  //   onFromChange(selectedDates, dateStr, instance) {
  //     this.$set(this.configTodateTimePicker, "minDate", dateStr);
  //   },
  //   onToChange(selectedDates, dateStr, instance) {
  //     this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
  //   },
  //   showAddBudgetModal() {
  //     this.getCategoryList();
  //     this.getBudgetCodeList();
  //     let isProjectSelected = this.projectId;
  //     if (isProjectSelected) {
  //       this.popupActive = true;
  //     } else {
  //       this.$vs.notify({
  //         time: 5000,
  //         title: "Select Project",
  //         iconPack: "feather",
  //         icon: "check_box",
  //         color: "warning",
  //       });
  //     }
  //     // this.getCompanyList();
  //   },
  //   deleteBudgetModal: function () {
  //     let isProjectSelected = this.projectId;
  //     if (isProjectSelected) {
  //       this.popupActiveDeleteBudget = true;
  //     } else {
  //       this.$vs.notify({
  //         time: 5000,
  //         title: "Select Project",
  //         iconPack: "feather",
  //         icon: "check_box",
  //         color: "warning",
  //       });
  //     }
  //   },
  //   onChangeCheckbox: function (e, value) {
  //     this.$vs.loading();
  //     let id = this.projectId;
  //     BudgetService.deleteBudget(id)
  //       .then((response) => {
  //         this.$vs.loading.close();
  //         const { data } = response;
  //         if (data.Status) {
  //           this.$vs.notify({
  //             time: 5000,
  //             title: "Updated!",
  //             text: data.Message,
  //             iconPack: "feather",
  //             icon: "check_box",
  //             color: "success",
  //           });
  //           setTimeout(() => {
  //             eventBus.$emit("refreshTableBudgetList", data);
  //           }, 1);
  //           this.popupActiveDeleteBudget = false;
  //         } else {
  //           this.$vs.loading.close();
  //           this.$vs.notify({
  //             time: 5000,
  //             title: "Error!",
  //             text: data.Message,
  //             iconPack: "feather",
  //             icon: "check_box",
  //             color: "warning",
  //           });
  //           this.popupActiveDeleteBudget = false;
  //         }
  //       })
  //       .catch((error) => {
  //         this.$vs.loading.close();
  //         this.$vs.notify({
  //           time: 5000,
  //           title: "Error!",
  //           text: error.Message,
  //           iconPack: "feather",
  //           icon: "check_box",
  //           color: "warning",
  //         });
  //       });
  //   },
  //   privStatus: function () {
  //     this.popupActiveDeleteBudget = false;
  //   },
  //   getBudgetCodeList: function () {
  //     let id = this.projectId;
  //     BudgetService.getBudgetCodes(id)
  //       .then((response) => {
  //         const { data } = response;
  //         if (data.Status) {
  //           if (data.data.length > 0) {
  //             this.budgetCodes = data.data;
  //           } else {
  //             this.budgetCodes = this.budgetCodesList;
  //           }
  //         } else {
  //           this.$vs.notify({
  //             time: 5000,
  //             title: "Data Not Found",
  //             text: data.Message,
  //             iconPack: "feather",
  //             icon: "check_box",
  //             color: "warning",
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         this.$vs.loading.close();
  //         this.$vs.notify({
  //           time: 5000,
  //           title: "Error!",
  //           text: error.Message,
  //           iconPack: "feather",
  //           icon: "check_box",
  //           color: "warning",
  //         });
  //       });
  //   },
  //   getCategoryList: function () {
  //     let id = this.projectId;
  //     CategoryService.getAllcategoriesByProject(id)
  //       .then((response) => {
  //         const { data } = response;
  //         if (data.Status == true || data.Status == "true") {
  //           this.categories = data.data.filter((x) => {
  //             if (String(x.parent_id) === "0") {
  //               return x;
  //             }
  //           });
  //           // this.subCategories = data.data.filter(x => {
  //           //   if (String(x.parent_id) !== "0") {
  //           //     return x;
  //           //   }
  //           // });
  //           this.categoryTemp = this.categories;
  //           // .filter(x => {
  //           //   return x.category_name;
  //           // });
  //         } else {
  //         }
  //       })
  //       .catch((error) => {
  //         this.$vs.loading.close();
  //         this.$vs.notify({
  //           time: 5000,
  //           title: "Error!",
  //           text: error.Message,
  //           iconPack: "feather",
  //           icon: "check_box",
  //           color: "warning",
  //         });
  //       });
  //   },
  //   onSuggestSelect: function (selected) {
  //     // this.getSubcategoryList(projectId);
  //     this.getSubcategoryList(selected.category_id || null);
  //   },
  //   getSubcategoryList: function (parent_id) {
  //     let ID = this.projectId;
  //     CategoryService.getSubCategoriesByParentCategory(parent_id, ID)
  //       .then((response) => {
  //         const { data } = response;
  //         if (data.Status) {
  //           this.subCategories = data.data;
  //           this.SubcategoryTemp = this.subCategories.filter((x) => {
  //             return x.category_name;
  //           });
  //         }
  //       })
  //       .catch((error) => {});
  //   },
  //   submitForm: function () {
  //     this.$validator.validateAll().then((result) => {
  //       this.submitStatus = false;
  //       if (result) {
  //         this.$vs.loading();
  //         let payload = {
  //           budget_code: this.form.budgetCode_value,
  //           category_name: this.form.selectedCategory,
  //           sub_category_name: this.form.selectedSubCategory,
  //           budget_amount: this.form.budgetAmount,
  //           budget_active: this.form.budget_active,
  //           project_id: this.projectId,
  //         };
  //         BudgetService.addBudget(payload)
  //           .then((response) => {
  //             this.$vs.loading.close();
  //             const { data } = response;
  //             if (data.Status) {
  //               this.$vs.notify({
  //                 time: 5000,
  //                 title: "Added Successfully",
  //                 text: data.Message,
  //                 iconPack: "feather",
  //                 icon: "check_box",
  //                 color: "success",
  //               });
  //               // event
  //               // this.$store.dispatch("getBudgetList", this.project_id);
  //               this.popupActive = false;
  //               setTimeout(() => {
  //                 eventBus.$emit("refreshBudgetTable", data);
  //               }, 1);
  //               this.clearForm();
  //             } else {
  //               this.$vs.loading.close();
  //               this.$vs.notify({
  //                 time: 5000,
  //                 title: "Error!",
  //                 text: data.Message,
  //                 iconPack: "feather",
  //                 icon: "check_box",
  //                 color: "warning",
  //               });
  //             }
  //           })
  //           .catch((error) => {
  //             this.$vs.loading.close();
  //             this.$vs.notify({
  //               time: 5000,
  //               title: "Error!",
  //               text: error.Message,
  //               iconPack: "feather",
  //               icon: "check_box",
  //               color: "warning",
  //             });
  //           });
  //         // if form have no errors
  //         // alert("form submitted!");
  //       } else {
  //         // form have errors
  //       }
  //     });
  //   },
  //   clearForm: function () {
  //     Object.keys(this.form).map((x, index) => {
  //       /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
  //     });
  //     // this.description = "";
  //     // this.value = [];
  //     this.$validator.reset();
  //   },
  //   getBudgetList: function (currentPage) {
  //     // let filter = {
  //     //   page: currentPage
  //     // };
  //     // BudgetService.getAllBudget(this.projectId)
  //     //   .then(response => {
  //     //     const { data } = response;
  //     //     if (data.err || data.Status == false) {
  //     //     } else {
  //     //       this.departments = data.data;
  //     //       this.totalPages = data.pagination.last_page;
  //     //       this.gridApi.sizeColumnsToFit();
  //     //     }
  //     //   })
  //     //   .catch(error => {
  //     //     console.log(error);
  //     //   });
  //   },
  // },
  // mounted() {
  //   if (!this.projectId) {
  //   }
  //   // if (this.checkPermission(["budget", "GET"])) {
  //   //   this.getBudgetList(this.currentPage);
  //   // }
  //   // this.gridApi.sizeColumnsToFit();
  //   this.gridApi = this.gridOptions.api;
  //   // this.$nextTick(() => {
  //   // setTimeout(() => {
  //   //   eventBus.$on("SetBudgetReportData", e => {
  //   //     this.searchQuery = e;
  //   //     this.gridApi.setQuickFilter(e);
  //   //     this.$forceUpdate();
  //   //   });
  //   // }, 5000);
  //   // });
  // },
};
</script>

<style>
table-data .table > thead > tr > th {
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  background-color: red !important;
}

.error-msg {
  min-height: 20px;
  color: red;
}

.sb-col {
  background-color: red !important;
  color: white;
}

table tbody th {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
}
.sb-fixed1 {
  position: sticky;
  left: 0;
  z-index: 2;
  width: 100px;
}
.sb-fixed {
  position: sticky;
  left: 116px !important;
  z-index: 2;
}
.sb-th span {
  width: 100%;
  padding: 5px;
  cursor: pointer;
}
</style>
